import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    TextField,
    Stack,
    Button,
    LinearProgress,
    Box,
    Alert,
} from '@mui/material';
import { FileSelectorComponent, ReactHlsPlayer } from 'ui';
import { addMedia, AddMediaResponse, uploadPresignedVideoUrl } from 'apis';

interface UploadVideoProps {
    channelId: string;
    title: string;
    setTitle: Dispatch<SetStateAction<string>>;
    setVideoId: Dispatch<SetStateAction<string>>;
    setNextEnabled: (index: number) => void;
    index: number;
}

const UploadVideo: React.FC<UploadVideoProps> = (props) => {
    const [file, setFile] = useState<File>();
    const [filePath, setFilePath] = useState<string>('');
    const [uploading, setUploading] = useState<boolean>(false);
    const [uploaded, setUploaded] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const [errorMessage, setErrorMessage] = useState<string>('');
    useEffect(() => {
        if (file) {
            setFilePath(URL.createObjectURL(file));
        }
    }, [file]);
    const submit = () => {
        if (file) {
            setErrorMessage('');
            setUploading(true);
            setUploaded(false);
            const videoData = new FormData();
            videoData.append('file', file);
            videoData.append('path', `channel/${props.channelId}/videos`);
            videoData.append('name', props.title);
            addMedia({
                title: props.title,
                tags: [],
                thumbnail: '',
                description: '',
                tier: 'Free',
                mediaType: 'video',
                audience: 'Unlisted',
                restricted: true,
            }).then((response: AddMediaResponse) => {
                if (response) {
                    videoData.append('videoId', response._id);
                    props.setVideoId(response._id);
                    uploadPresignedVideoUrl(
                        response._id,
                        props.title,
                        file,
                        (data: { loaded: number; total: number }) => {
                            setProgress(
                                Math.round((100 * data.loaded) / data.total),
                            );
                        },
                    )
                        .then((response) => {
                            setUploaded(true);
                            setUploading(true);
                            if (response === null) {
                                setErrorMessage(
                                    'Unknown error occurred while uploading your video.  Please try again',
                                );
                            } else {
                                props.setNextEnabled(props.index);
                            }
                        })
                        .catch((err) => console.error(err));
                } else {
                    setErrorMessage(
                        'Unknown error occurred while creating your video.  Please try again',
                    );
                    setUploading(false);
                }
            });
        }
    };
    return (
        <Stack direction="column" alignItems="center" spacing={2}>
            {uploaded && errorMessage === '' && (
                <Alert severity="success">Successfully uploaded video</Alert>
            )}
            {uploaded && errorMessage !== '' && (
                <Alert severity="error">{errorMessage}</Alert>
            )}
            <TextField
                required
                disabled={uploaded && errorMessage === ''}
                id="outlined-required"
                label="Title"
                value={props.title}
                onChange={(e: { target: { value: string } }) =>
                    props.setTitle(e.target.value)
                }
            />
            <FileSelectorComponent
                fileType="video/*"
                id="upload-video-id"
                width="25%"
                height="100%"
                title="Select video"
                setCurrentFile={setFile}
                disabled={uploaded && errorMessage === ''}
            />
            {file !== undefined && (
                <ReactHlsPlayer
                    hls={false}
                    style={{ width: '640px', height: '480px' }}
                    src={filePath}
                    autoPlay={false}
                />
            )}
            {file && (
                <Button
                    variant="contained"
                    disabled={
                        uploading ||
                        props.title === '' ||
                        (uploaded && errorMessage === '')
                    }
                    onClick={submit}
                >
                    Upload Video
                </Button>
            )}
            <Box sx={{ width: '100%' }}>
                {(uploading || uploaded) && (
                    <LinearProgress
                        variant="determinate"
                        color={
                            uploaded
                                ? errorMessage === ''
                                    ? 'success'
                                    : 'error'
                                : 'primary'
                        }
                        value={progress}
                    />
                )}
            </Box>
        </Stack>
    );
};

export default UploadVideo;
