import { Box, Tab, Tabs } from '@mui/material';
import {
    getInfo,
    GetInfoResponse,
    updateInfo,
    uploadPresignedImageUrl,
} from 'apis';
import React, { SyntheticEvent, useState } from 'react';
import { useParams } from 'react-router-dom';
import { TabPanel } from 'ui';
import Images from './Images';
import Info from './Info';
import { useQuery } from '@tanstack/react-query';

const Customization: React.FC = () => {
    const { channelId } = useParams();
    const [submitting, setSubmitting] = useState<boolean>(false);
    if (channelId === undefined) {
        return <div>Not Found</div>;
    }
    const [currentTab, setCurrentTab] = useState<number>(0);
    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
    };
    const uploadImages = async (avatarImage?: File, bannerImage?: File) => {
        setSubmitting(true);
        if (avatarImage) {
            const avatarImageData = new FormData();
            avatarImageData.append('file', avatarImage);
            avatarImageData.append('path', `${channelId}/channel/avatar`);
            avatarImageData.append('newName', channelId);
            await uploadPresignedImageUrl('avatar', avatarImage);
            await updateInfo({
                avatarThumbnail: avatarImage.name,
            });
        }
        if (bannerImage) {
            const bannerImageData = new FormData();
            bannerImageData.append('file', bannerImage);
            bannerImageData.append('path', 'banner');
            bannerImageData.append('newName', channelId);
            await uploadPresignedImageUrl('banner', bannerImage);
            await updateInfo({
                bannerThumbnail: bannerImage.name,
            });
        }
        setSubmitting(false);
    };
    const updateChannelInfo = async (bio: string, description: string) => {
        setSubmitting(true);
        await updateInfo({
            bio: bio,
            description: description,
        });
        setSubmitting(false);
    };
    const accountInfo = useQuery<GetInfoResponse>(
        [`account-info-${channelId}`],
        () => getInfo({ channelId: channelId }),
        {
            refetchInterval: Infinity,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
        },
    );
    if (accountInfo.isLoading) {
        return <div>Loading</div>;
    }
    if (accountInfo.isError || !accountInfo.data) {
        return <div>error</div>;
    }
    return (
        <>
            <Box
                sx={{ paddingTop: 5, borderBottom: 1, borderColor: 'divider' }}
            >
                <Tabs value={currentTab} onChange={handleChange}>
                    <Tab label="Details" />
                    <Tab label="Images" />
                </Tabs>
            </Box>
            <Box sx={{ width: '50vw' }}>
                <TabPanel currentIndex={currentTab} tabIndex={0}>
                    <Info
                        bio={accountInfo.data.bio}
                        description={accountInfo.data.description}
                        channelId={channelId}
                        updateChannelInfo={updateChannelInfo}
                        submitting={submitting}
                    />
                </TabPanel>
                <TabPanel currentIndex={currentTab} tabIndex={1}>
                    <Images
                        channelId={channelId}
                        avatarThumbnail={accountInfo.data?.avatarThumbnail}
                        bannerThumbnail={accountInfo.data?.bannerThumbnail}
                        submitting={submitting}
                        uploadImages={uploadImages}
                    />
                </TabPanel>
            </Box>
        </>
    );
};

export default Customization;
