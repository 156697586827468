import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Card, CardContent } from '@mui/material';
import { labels } from './MetricsChart.consts';
import { TimeFilter } from './MetricsChart.types';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
);

export const options = {
    responsive: true,
    plugins: {
        scales: {
            y: {
                stacked: true,
            },
        },
        legend: {
            position: 'top' as const,
        },
        title: {
            display: true,
            text: 'Chart.js Line Chart',
        },
    },
};
interface MetricsChartProps {
    timeFilter: TimeFilter;
    title: string;
}
const MetricsChart: React.FC<MetricsChartProps> = (props) => {
    options.plugins.title.text = props.title;
    const data = {
        labels: labels[props.timeFilter],
        datasets: [
            {
                label: 'Dataset 1',
                data: [
                    { y: 20, x: '2' },
                    { y: 40, x: '2' },
                    { y: 60, x: '2' },
                ],
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
        ],
    };
    return (
        <Card>
            <CardContent>
                <Line options={options} data={data} />
            </CardContent>
        </Card>
    );
};

export default MetricsChart;
