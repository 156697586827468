import React, { createContext, useContext, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

interface ModalProps extends DialogProps {
    title: string;
}

type ModalContextType = {
    showModal: (modalProps?: ModalProps) => void;
    hideModal: () => void;
};

export const ModalContext = createContext<ModalContextType>({
    showModal: () => {
        throw new Error('Not Implemented');
    },
    hideModal: () => {
        throw new Error('Not Implemented');
    },
});

export const ModalProvider: React.FC<
    React.PropsWithChildren<{ empty?: undefined }>
> = (props) => {
    const [open, setOpen] = useState<boolean>(false);
    const [modalProps, setModalProps] = useState<ModalProps | undefined>();
    const showModal = (modalProps?: ModalProps) => {
        setOpen(true);
        setModalProps(modalProps);
    };
    const hideModal = () => {
        setOpen(false);
    };
    return (
        <ModalContext.Provider
            value={{
                showModal: showModal,
                hideModal: hideModal,
            }}
        >
            <Dialog {...modalProps} open={open} onBackdropClick={hideModal}>
                <DialogTitle sx={{ m: 0, p: 2 }}>
                    {modalProps?.title || ''}
                    <IconButton
                        aria-label="close"
                        onClick={hideModal}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>{modalProps?.children}</DialogContent>
            </Dialog>
            {props.children}
        </ModalContext.Provider>
    );
};

export function useModalContext() {
    return useContext(ModalContext);
}
