import { Alert, Skeleton, Stack } from '@mui/material';
import React from 'react';

interface TiersSkeletonProps {
    isLoading: boolean;
    isError: boolean;
}

const TiersSkeleton: React.FC<React.PropsWithChildren<TiersSkeletonProps>> = (
    props,
) => {
    if (props.isLoading) {
        return (
            <Stack pt={5} spacing={4}>
                <Stack direction="row" spacing={2}>
                    <Skeleton variant="rectangular" width={270} height={48} />
                </Stack>
                <Skeleton variant="rectangular" width={500} height={200} />
            </Stack>
        );
    }
    if (props.isError) {
        return <Alert severity="error">Unable to load tier info</Alert>;
    }
    return <>{props.children}</>;
};

export default TiersSkeleton;
