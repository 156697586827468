import { Api } from '../common/apis';

import * as Types from './NotificationsAPIs.types';

const apis = new Api('/api/v1/notifications');

export const getUnreadNotificationsCount =
    async (): Promise<Types.GetUnreadNotificationsCountResponse> => {
        return await apis.get<
            Types.GetUnreadNotificationsCountRequest,
            Types.GetUnreadNotificationsCountResponse
        >('getUnreadNotificationsCount');
    };

export const readNotifications =
    async (): Promise<Types.ReadNotificationsResponse> => {
        return await apis.get<
            Types.ReadNotificationsRequest,
            Types.ReadNotificationsResponse
        >('readNotifications');
    };

export const getUserNotificationsSettings =
    async (): Promise<Types.GetUserNotificationsSettingsResponse> => {
        return await apis.get<
            Types.UpdateUserNotificationsSettingsRequest,
            Types.UpdateUserNotificationsSettingsResponse
        >('getUserNotificationsSettings');
    };

export const updateUserNotifications = async (
    request: Types.UpdateUserNotificationsSettingsRequest,
): Promise<Types.UpdateUserNotificationsSettingsResponse> => {
    return await apis.put<
        Types.UpdateUserNotificationsSettingsRequest,
        Types.UpdateUserNotificationsSettingsResponse
    >('updateUserNotifications', request);
};
