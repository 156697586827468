import React from 'react';
import { Button, IconButton, Stack } from '@mui/material';
import { MenuButton } from 'ui';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useModalContext } from 'hooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteMedia } from 'apis';
import { LoadingButton } from '@mui/lab';

interface VideoEditDropdownProps {
    videoId: string;
    title: string;
    channelId: string;
}

interface ConfirmDialogProps {
    videoId: string;
    channelId: string;
    close: () => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
    const queryClient = useQueryClient();
    const deleteVideo = useMutation(
        ['delete video'],
        () => deleteMedia({ _id: props.videoId }),
        {
            onSuccess: () => {
                queryClient
                    .invalidateQueries({
                        queryKey: [`get-personal-videos-${props.channelId}`],
                    })
                    .then(() => props.close());
            },
        },
    );
    return (
        <Stack direction="row" justifyContent="space-between">
            <LoadingButton
                loading={deleteVideo.isLoading}
                onClick={() => deleteVideo.mutate()}
            >
                Yes
            </LoadingButton>
            <Button disabled={deleteVideo.isLoading} onClick={props.close}>
                No
            </Button>
        </Stack>
    );
};

const VideoEditDropdown: React.FC<VideoEditDropdownProps> = (props) => {
    const modalContext = useModalContext();

    const deleteVideo = (title: string, videoId: string, channelId: string) => {
        modalContext.hideModal();
        modalContext.showModal({
            title: `Delete ${title}`,
            open: true,
            children: (
                <ConfirmDialog
                    channelId={channelId}
                    videoId={videoId}
                    close={modalContext.hideModal}
                />
            ),
        });
    };
    return (
        <IconButton
            data-cy=""
            style={{ paddingTop: '0px', paddingBottom: '0px' }}
            size="large"
            color="inherit"
        >
            <MenuButton
                IconProps={{
                    size: 'large',
                    edge: 'end',
                    color: 'inherit',
                }}
                menuId={'test'}
                menuItems={[
                    {
                        title: 'Delete',
                        onClick: () =>
                            deleteVideo(
                                props.title,
                                props.videoId,
                                props.channelId,
                            ),
                    },
                ]}
            >
                <MoreHorizIcon />
            </MenuButton>
        </IconButton>
    );
};

export default VideoEditDropdown;
