import React, { useState } from 'react';
import { Layout as BaseLayout } from 'ui';
import SideBar from './SideBar';
import NavBar from './NavBar';

const Layout: React.FC<React.PropsWithChildren<unknown>> = (props) => {
    const [open, setOpen] = useState<boolean>(true);
    return (
        <BaseLayout
            open={open}
            setOpen={setOpen}
            NavBar={<NavBar />}
            SideBar={<SideBar open={open} />}
        >
            {props.children}
        </BaseLayout>
    );
};

export default Layout;
