import React, { useState, createContext, useContext } from 'react';
import { Snackbar, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type NotificationsContextType = {
    showNotification: (props: {
        autoHideDuration?: number;
        message: string;
    }) => void;
};

export const NotificationsContext = createContext<NotificationsContextType>({
    showNotification: () => {
        throw new Error('');
    },
});

export const NotificaitonsProvider: React.FC<
    React.PropsWithChildren<{ empty?: undefined }>
> = (props) => {
    const [open, setOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const showNotification = (not: {
        autoHideDuration?: number;
        message: string;
    }) => {
        setMessage(not.message);
        setOpen(true);
    };
    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setOpen(false)}
            >
                <CloseIcon fontSize="large" />
            </IconButton>
        </React.Fragment>
    );
    return (
        <NotificationsContext.Provider
            value={{
                showNotification: showNotification,
            }}
        >
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={() => setOpen(false)}
                message={message}
                action={action}
            />
            {props.children}
        </NotificationsContext.Provider>
    );
};

export function useNotifications() {
    return useContext(NotificationsContext);
}
