import { Api } from '../common/apis';

import * as Types from './TransactionAPIs.types';

const apis = new Api('/api/v1/transactions');

export const blueChipTransactions = async (
    request: Types.BlueChipTransactionsRequest,
): Promise<Types.BlueChipTransactionsResponse> => {
    return await apis.get<
        Types.BlueChipTransactionsRequest,
        Types.BlueChipTransactionsResponse
    >('bluechipTransactions', request);
};
export const calculateTokenWorth = async (
    request: Types.CalculateTokenWorthRequest,
): Promise<Types.CalculateTokenCostResponse> => {
    return await apis.post<
        Types.CalculateTokenWorthRequest,
        Types.CalculateTokenWorthResponse
    >('calculateTokenWorth', request);
};
export const calculateTokenCost = async (
    request: Types.CalculateTokenCostRequest,
): Promise<Types.CalculateTokenCostResponse> => {
    return await apis.post<
        Types.CalculateTokenCostRequest,
        Types.CalculateTokenCostResponse
    >('calculateTokenCost', request);
};
export const buyBlueChip = async (
    request: Types.BuyBlueChipRequest,
): Promise<Types.BuyBlueChipResponse> => {
    return await apis.post<Types.BuyBlueChipRequest, Types.BuyBlueChipResponse>(
        'buyBluechip',
        request,
    );
};
export const sellBlueChip = async (
    request: Types.SellBlueChipRequest,
): Promise<Types.SellBlueChipResponse> => {
    return await apis.post<
        Types.SellBlueChipRequest,
        Types.SellBlueChipResponse
    >('sellBluechip', request);
};
export const paymentMethods =
    async (): Promise<Types.PaymentMethodsResponse> => {
        return await apis.get<
            Types.PaymentMethodsRequest,
            Types.PaymentMethodsResponse
        >('paymentMethods');
    };
