import React, { useEffect, useState } from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import { MenuButton } from './MenuButton';
import { getUnreadNotificationsCount } from 'apis';

export const NotificationButton: React.FC = () => {
    const [notificationsCount, setNotificationsCount] = useState<number>(0);
    useEffect(() => {
        /*getUnreadNotificationsCount().then((response) => {
            if (response) {
                setNotificationsCount(response.count);
            }
        });*/
    }, []);
    return (
        <IconButton
            data-cy="nav-bar-notifications-button"
            style={{ paddingTop: '0px', paddingBottom: '0px' }}
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
        >
            <Badge badgeContent={notificationsCount} color="error">
                <MenuButton
                    IconProps={{
                        size: 'large',
                        edge: 'end',
                        'aria-label': 'account of current user',
                        'aria-haspopup': 'true',
                        color: 'inherit',
                    }}
                    menuId={'primary-profile-account-menu'}
                    menuItems={[]}
                >
                    <NotificationsIcon />
                </MenuButton>
            </Badge>
        </IconButton>
    );
};
