import axios, { AxiosInstance, AxiosError, AxiosResponse } from 'axios';
axios.defaults.withCredentials = true;
const NO_RETRY_HEADER = 'x-no-retry';

export class Api {
    private readonly _axiosPost: AxiosInstance;
    private readonly _axiosRefresh: AxiosInstance;
    private readonly _axios: AxiosInstance;
    private readonly _baseURL: string;
    public constructor(baseURL: string) {
        this._baseURL = baseURL;
        this._axiosPost = axios.create({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
        });
        this._axiosRefresh = axios.create({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
        });
        const postRefreshInterceptor = async (error: AxiosError) => {
            if (
                !axios.isCancel(error) &&
                axios.isAxiosError(error) &&
                error?.response?.status === 401
            ) {
                if (
                    error.config.headers &&
                    error.config.headers[NO_RETRY_HEADER] === 'true'
                ) {
                    return Promise.reject(error);
                }
                error.config.headers ||= {};
                error.config.headers[NO_RETRY_HEADER] = 'true'; // string val only
                await this._axiosRefresh.post('/api/v1/auth/refresh');
                return this._axiosPost(error.config);
            }
            return Promise.reject(error);
        };
        postRefreshInterceptor.bind(this);
        const getRefreshInterceptor = async (error: AxiosError) => {
            if (
                !axios.isCancel(error) &&
                axios.isAxiosError(error) &&
                error?.response?.status === 401
            ) {
                if (
                    error.config.headers &&
                    error.config.headers[NO_RETRY_HEADER] === 'true'
                ) {
                    return Promise.reject(error);
                }
                error.config.headers ||= {};
                error.config.headers[NO_RETRY_HEADER] = 'true'; // string val only
                await this._axiosRefresh.post('/api/v1/auth/refresh');
                return this._axios(error.config);
            }
            return Promise.reject(error);
        };
        getRefreshInterceptor.bind(this);
        this._axiosPost.interceptors.response.use((response: AxiosResponse) => {
            return response;
        }, postRefreshInterceptor);
        this._axios = axios.create({
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                Accept: 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
        });
        this._axios.interceptors.response.use((response: AxiosResponse) => {
            return response;
        }, getRefreshInterceptor);
    }
    public async post<Request, Response>(
        api: string,
        data?: Request,
        onUploadProgress?: (data: { loaded: number; total: number }) => void,
    ): Promise<Response> {
        const response = await this._axiosPost.post(
            `${this._baseURL}/${api}`,
            data,
            {
                onUploadProgress: onUploadProgress,
            },
        );
        return response.data;
    }
    public async get<Request, Response>(
        api: string,
        params?: Request,
    ): Promise<Response> {
        const response = await this._axios.get(`${this._baseURL}/${api}`, {
            params: params,
        });
        return response?.data;
    }
    public async delete<Request, Response>(
        api: string,
        data?: Request,
    ): Promise<Response> {
        const response = await this._axios.delete(`${this._baseURL}/${api}`, {
            data: data,
        });
        return response?.data;
    }
    public async put<Request, Response>(
        api: string,
        data?: Request,
        onUploadProgress?: (data: { loaded: number; total: number }) => void,
    ): Promise<Response> {
        const response = await this._axios.put(
            `${this._baseURL}/${api}`,
            data,
            {
                onUploadProgress: onUploadProgress,
            },
        );
        return response?.data;
    }
    public async patch<Request, Response>(
        api: string,
        data?: Request,
    ): Promise<Response> {
        const response = await this._axios.patch(
            `${this._baseURL}/${api}`,
            data,
        );
        return response?.data;
    }
}
