import React from 'react';
import { NotificationButton, CreateButton, ProfileButton } from 'ui';
import { Button, Stack } from '@mui/material';
import { useAccountContext } from 'hooks';
import { Link } from 'react-router-dom';

const NavBar: React.FC = () => {
    const { isLoggedIn } = useAccountContext();
    return (
        <Stack direction="row" justifyContent="space-evenly" width={'100%'}>
            <Stack
                justifyContent="flex-start"
                width="100%"
                alignItems="flex-start"
            >
                <Link
                    to="/home"
                    style={{
                        color: 'inherit',
                        textDecoration: 'none',
                        fontSize: 'x-large',
                    }}
                >
                    Bluechip Studio
                </Link>
            </Stack>
            <Stack
                width="100%"
                justifyContent="flex-end"
                direction="row"
                alignItems="center"
            >
                {!isLoggedIn && (
                    <Link
                        to="/login"
                        style={{
                            color: 'inherit',
                            textDecoration: 'none',
                            fontSize: 'xx-large',
                        }}
                    >
                        <Button variant="outlined" color="inherit">
                            Sign In
                        </Button>
                    </Link>
                )}
                {isLoggedIn && (
                    <>
                        <CreateButton />
                        <NotificationButton />
                        <ProfileButton />
                    </>
                )}
            </Stack>
        </Stack>
    );
};

export default NavBar;
