import React from 'react';
import { Card, Divider, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getChannelStats } from 'apis';
import ChannelStatisticsSkeleton from './ChannelStatisticsSkeleton';

interface ChannelStatisticsProps {
    channelId: string;
}

const ChannelStatistics: React.FC<ChannelStatisticsProps> = (props) => {
    const { isLoading, isError, data } = useQuery(
        [`channel-${props.channelId}-channel-stats`],
        () =>
            getChannelStats({
                channelId: props.channelId,
            }),
    );
    return (
        <ChannelStatisticsSkeleton isLoading={isLoading} isError={isError}>
            <Card sx={{ maxWidth: 365 }}>
                <Typography variant="h5" gutterBottom component="u">
                    Channel Statistics
                </Typography>
                <Divider light />
                <Typography variant="body1" gutterBottom component="div">
                    Uploaded Videos:{data?.videos}
                </Typography>
                <Typography variant="body1" gutterBottom component="div">
                    Channel Views:{data?.views}
                </Typography>
                <Typography variant="body1" gutterBottom component="div">
                    Subscribers:{data?.subscribers}
                </Typography>
            </Card>
        </ChannelStatisticsSkeleton>
    );
};

export default ChannelStatistics;
