import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import ChannelViewCommentsTable from '../../../../components/channel/[channelId]/video/content/ChannelViewCommentsTable';

const Comments: React.FC = () => {
    const { channelId } = useParams();
    if (channelId === undefined) {
        return <div>Channel Not Found</div>;
    }
    return (
        <Stack sx={{ paddingTop: '40px' }}>
            <Typography variant="h5" gutterBottom component="div">
                Comments for videos
            </Typography>
            <Box>
                <ChannelViewCommentsTable />
            </Box>
        </Stack>
    );
};

export default Comments;
