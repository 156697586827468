import React, { createContext, useContext, useEffect, useState } from 'react';
import * as apis from 'apis';

type AccountContextType = {
    isLoggedIn: boolean;
    userInfo?: apis.LoginResponse;
    userSettings?: apis.GetUserSettingsResponse;
    userNotificationsSettings?: apis.GetUserNotificationsSettingsResponse;
    userSubscriptions?: apis.GetUserSubscriptionsResponse;
    userPayments?: apis.GetPaymentInfosResponse;
    login: (data: apis.LoginRequest) => Promise<boolean>;
    register: (data: apis.RegisterRequest) => Promise<boolean>;
    logout: () => Promise<boolean>;
    refresh: () => Promise<boolean>;
    isUserLoggedIn: () => Promise<boolean>;
    updateUserSettings: (
        newSettings: Partial<apis.GetUserSettingsResponse>,
    ) => void;
    updateUserNotificationsSettings: (
        newSettings: Partial<apis.GetUserNotificationsSettingsResponse>,
    ) => void;
};

export const AccountContext = createContext<AccountContextType>({
    isLoggedIn: false,
    userInfo: undefined,
    userSettings: undefined,
    userNotificationsSettings: undefined,
    userSubscriptions: undefined,
    userPayments: undefined,
    login: async () => {
        throw new Error('Not implemented');
    },
    register: async () => {
        throw new Error('Not implemented');
    },
    logout: async () => {
        throw new Error('Not implemented');
    },
    refresh: async () => {
        throw new Error('Not implemented');
    },
    isUserLoggedIn: async () => {
        throw new Error('Not implemented');
    },
    updateUserSettings: () => {
        throw new Error('Not implemented');
    },
    updateUserNotificationsSettings: () => {
        throw new Error('Not implemented');
    },
});

export const AccountProvider: React.FC<
    React.PropsWithChildren<{ empty?: undefined }>
> = (props) => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [userInfo, setUserInfo] = useState<apis.LoginResponse | undefined>(
        undefined,
    );
    const [userSettings, setUserSettings] = useState<
        apis.GetUserSettingsResponse | undefined
    >(undefined);
    const [userNotificationsSettings, setUserNotificationSettings] = useState<
        apis.GetUserNotificationsSettingsResponse | undefined
    >(undefined);
    const [userSubscriptions, setUserSubscriptions] = useState<
        apis.GetUserSubscriptionsResponse | undefined
    >(undefined);
    const [userPayments, setUserPayments] = useState<
        apis.GetPaymentInfosResponse | undefined
    >(undefined);
    const login = async (data: apis.LoginRequest) => {
        const user = await apis.login(data);
        try {
            setIsLoggedIn(true);
            setUserInfo(user);
        } catch {
            setIsLoggedIn(false);
            setUserInfo(undefined);
            return false;
        }
        return true;
    };
    const register = async (data: apis.RegisterRequest) => {
        const message = await apis.register(data);
        try {
            return true;
        } catch {
            return false;
        }
    };
    const logout = async () => {
        await apis.logout();
        setUserInfo(undefined);
        try {
            return true;
        } catch {
            return false;
        }
    };
    const refresh = async () => {
        const user = await apis.refresh();
        try {
            setUserInfo(user);
            setIsLoggedIn(true);
            return true;
        } catch {
            setUserInfo(undefined);
            setIsLoggedIn(false);
            return false;
        }
    };
    const isUserLoggedIn = async () => {
        const user = await apis.isLoggedIn();
        if (user) {
            setIsLoggedIn(user);
            setUserInfo(user);
            return true;
        } else {
            setIsLoggedIn(false);
            setUserInfo(undefined);
            return false;
        }
    };
    const updateUserSettings = (
        newSettings: Partial<apis.GetUserSettingsResponse>,
    ) => {
        const temp: apis.GetUserSettingsResponse = {
            language: newSettings?.language ?? userSettings?.language ?? 'EN',
            contactMe:
                newSettings?.contactMe ?? userSettings?.contactMe ?? false,
            rememberSearch:
                newSettings?.rememberSearch ??
                userSettings?.rememberSearch ??
                false,
            privateSubscriptions:
                newSettings?.privateSubscriptions ??
                userSettings?.privateSubscriptions ??
                false,
            recentPlayed:
                newSettings?.recentPlayed ??
                userSettings?.recentPlayed ??
                false,
            chaosMode:
                newSettings?.chaosMode ?? userSettings?.chaosMode ?? false,
            privatePlaylists:
                newSettings?.privatePlaylists ??
                userSettings?.privatePlaylists ??
                false,
            darkMode: newSettings?.darkMode ?? userSettings?.darkMode ?? false,
            highContrast:
                newSettings?.highContrast ??
                userSettings?.highContrast ??
                false,
        };
        setUserSettings({
            ...temp,
        });
    };
    const updateUserNotificationsSettings = (
        newSettings: Partial<apis.GetUserNotificationsSettingsResponse>,
    ) => {
        const temp: apis.GetUserNotificationsSettingsResponse = {
            subscriptions:
                newSettings?.subscriptions ??
                userNotificationsSettings?.subscriptions ??
                false,
            recommended:
                newSettings?.recommended ??
                userNotificationsSettings?.recommended ??
                false,
            activity:
                newSettings?.activity ??
                userNotificationsSettings?.activity ??
                false,
            replies:
                newSettings?.replies ??
                userNotificationsSettings?.replies ??
                false,
            mentions:
                newSettings?.mentions ??
                userNotificationsSettings?.mentions ??
                false,
            sharedContent:
                newSettings?.sharedContent ??
                userNotificationsSettings?.sharedContent ??
                false,
            general:
                newSettings?.general ??
                userNotificationsSettings?.general ??
                false,
            creator:
                newSettings?.creator ??
                userNotificationsSettings?.creator ??
                false,
            emailLanguage:
                newSettings?.emailLanguage ??
                userNotificationsSettings?.emailLanguage ??
                'EN',
        };
        setUserNotificationSettings({
            ...temp,
        });
    };
    useEffect(() => {
        isUserLoggedIn();
    }, []);
    useEffect(() => {
        if (userInfo) {
            apis.getUserSettings().then((response) =>
                setUserSettings(response || undefined),
            );
            apis.getUserNotificationsSettings().then((response) =>
                setUserNotificationSettings(response),
            );
            apis.getUserSubscriptions().then((response) =>
                setUserSubscriptions(response || undefined),
            );
            apis.getPaymentInfos().then((response) =>
                setUserPayments(response || undefined),
            );
        }
    }, [userInfo]);
    return (
        <AccountContext.Provider
            value={{
                isLoggedIn: isLoggedIn,
                userInfo: userInfo,
                userPayments: userPayments,
                userSubscriptions: userSubscriptions,
                userSettings: userSettings,
                userNotificationsSettings: userNotificationsSettings,
                login: login,
                register: register,
                logout: logout,
                refresh: refresh,
                isUserLoggedIn: isUserLoggedIn,
                updateUserSettings: updateUserSettings,
                updateUserNotificationsSettings:
                    updateUserNotificationsSettings,
            }}
        >
            {props.children}
        </AccountContext.Provider>
    );
};

export function useAccountContext() {
    return useContext(AccountContext);
}
