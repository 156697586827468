import React from 'react';
import { Stack, Typography } from '@mui/material';
import { VideoFormat, VideoProgressStatus } from 'apis';
import { videoFormatToReadableString } from './VideoUploadProgressBar.consts';

interface VidoeUploadProgressBarProps {
    quality: VideoFormat;
    progress: number;
    status: VideoProgressStatus;
    title: string;
    errorMessage: string;
}

const VidoeUploadProgressBar: React.FC<VidoeUploadProgressBarProps> = (
    props,
) => {
    return (
        <Stack direction="row" spacing={1}>
            <Typography variant="body2" color="text.secondary">
                {props.quality} -{' '}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {videoFormatToReadableString[props.status]}
            </Typography>
            <Typography variant="body2" color="text.secondary">{`${Math.round(
                props.progress,
            )}%`}</Typography>
            {props.status === 'FAILED' && (
                <Typography variant="body2" color="text.secondary">
                    {props.errorMessage}
                </Typography>
            )}
        </Stack>
    );
};

export default VidoeUploadProgressBar;
