import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import CommentIcon from '@mui/icons-material/Comment';
import PeopleIcon from '@mui/icons-material/People';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { Tooltip } from '@mui/material';
import { useAccountContext } from 'hooks';
type Item = {
    title: string;
    icon: React.ReactNode;
    link: string;
};
const items: Item[] = [
    {
        title: 'Dashboard',
        icon: <DashboardIcon />,
        link: 'videos/dashboard',
    },
    {
        title: 'Content',
        icon: <VideoLibraryIcon />,
        link: 'videos/content',
    },
    {
        title: 'Comments',
        icon: <CommentIcon />,
        link: 'comments',
    },
    {
        title: 'Analytics',
        icon: <AssessmentIcon />,
        link: 'analytics/tab-overview',
    },
    {
        title: 'Customization',
        icon: <PeopleIcon />,
        link: 'editing',
    },
    {
        title: 'Subscription Tiers',
        icon: <EditIcon />,
        link: 'subscriptions/tiers',
    },
];

const MainListItems: React.FC<{ channelId: string; open: boolean }> = ({
    channelId,
    open,
}) => (
    <>
        {items.map((item, index) => (
            <Link
                to={`/create/channel/${channelId}/${item.link}`}
                key={index.toString()}
                style={{ color: 'black', textDecoration: 'none' }}
            >
                <Tooltip title={item.title} followCursor>
                    <ListItemButton sx={{ paddingLeft: '20px' }}>
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        {open && <ListItemText primary={item.title} />}
                    </ListItemButton>
                </Tooltip>
            </Link>
        ))}
    </>
);

const secondaryListItems = <></>;

interface SideBarProps {
    open: boolean;
}

const SideBar: React.FC<SideBarProps> = (props) => {
    const { isLoggedIn, userInfo } = useAccountContext();
    if (!isLoggedIn || userInfo === undefined) {
        return <List component="nav"></List>;
    }
    return (
        <List component="nav">
            <MainListItems channelId={userInfo.username} open={props.open} />
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
        </List>
    );
};

export default SideBar;
