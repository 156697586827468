import { TimeFilter } from './MetricsChart.types';

export const daysBetweenTwoDates = (date1: Date, date2: Date): number => {
    // To calculate the time difference of two dates
    const timeDiff = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    const dayDiff = timeDiff / (1000 * 3600 * 24);
    return Math.ceil(dayDiff);
};

export const labels: Record<TimeFilter, string[]> = {
    'Last 7 Days': [...new Array(7)].map((_, index) => {
        const date = new Date();
        date.setDate(date.getDate() - (7 - index));
        return date.toLocaleString('en-us', { weekday: 'long' });
    }),
    'Last 28 Days': [...new Array(28)].map((_, index) => {
        const date = new Date();
        date.setDate(date.getDate() - (28 - index));
        return date.toDateString();
    }),
    'Last 90 Days': [...new Array(90)].map((_, index) => {
        const date = new Date();
        date.setDate(date.getDate() - (90 - index));
        return date.toDateString();
    }),
    'Last 365 Days': [...new Array(365)].map((_, index) => {
        const date = new Date();
        date.setDate(date.getDate() - (365 - index));
        return date.toDateString();
    }),
    Lifetime: [
        ...new Array(daysBetweenTwoDates(new Date('12/04/2022'), new Date())),
    ].map((index) => {
        const date = new Date();
        date.setDate(
            date.getDate() -
                (daysBetweenTwoDates(new Date('12/04/2022'), new Date()) -
                    index),
        );
        return date.toDateString();
    }),
};
