import React from 'react';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import { MenuButton } from 'ui';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useModalContext } from 'hooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteComment } from 'apis';
import { LoadingButton } from '@mui/lab';

interface CommentEditDropdownProps {
    parentId: string;
    commentId: string;
    body: string;
    username: string;
}

interface ConfirmDialogProps {
    commentId: string;
    parentId: string;
    body: string;
    close: () => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
    const queryClient = useQueryClient();
    const deleteCommentMutate = useMutation(
        ['delete comment'],
        () => deleteComment({ _id: props.commentId }),
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries({
                    queryKey: ['get-comments-for-channel'],
                });
                await queryClient.invalidateQueries({
                    queryKey: [`get-${props.parentId}-comments`],
                });
                await queryClient.invalidateQueries({
                    queryKey: [`video-${props.parentId}-comments`],
                });
                props.close();
            },
        },
    );
    return (
        <Stack>
            <Typography>{props.body}</Typography>
            <Stack direction="row" justifyContent="space-between">
                <LoadingButton
                    loading={deleteCommentMutate.isLoading}
                    onClick={() => deleteCommentMutate.mutate()}
                >
                    Yes
                </LoadingButton>
                <Button
                    disabled={deleteCommentMutate.isLoading}
                    onClick={props.close}
                >
                    No
                </Button>
            </Stack>
        </Stack>
    );
};

const CommentEditDropdown: React.FC<CommentEditDropdownProps> = (props) => {
    const modalContext = useModalContext();

    const deleteComment = (
        commentId: string,
        parentId: string,
        body: string,
        username: string,
    ) => {
        modalContext.hideModal();
        modalContext.showModal({
            title: `Delete ${commentId} from ${username}`,
            open: true,
            children: (
                <ConfirmDialog
                    parentId={parentId}
                    commentId={commentId}
                    body={body}
                    close={modalContext.hideModal}
                />
            ),
        });
    };
    return (
        <IconButton
            style={{ paddingTop: '0px', paddingBottom: '0px' }}
            size="large"
            color="inherit"
        >
            <MenuButton
                IconProps={{
                    size: 'large',
                    edge: 'end',
                    color: 'inherit',
                }}
                menuId={'comment_delete'}
                menuItems={[
                    {
                        title: 'Delete',
                        onClick: () =>
                            deleteComment(
                                props.commentId,
                                props.parentId,
                                props.body,
                                props.username,
                            ),
                    },
                ]}
            >
                <MoreHorizIcon />
            </MenuButton>
        </IconButton>
    );
};

export default CommentEditDropdown;
