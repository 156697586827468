import {
    Box,
    Checkbox,
    Paper,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    TableContainer,
    Table,
    TableBody,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import {
    getVideosForPersonalChannel,
    GetVideosForPersonalChannelResponse,
} from 'apis';
import React from 'react';

import { useQuery } from '@tanstack/react-query';
import VideoEditTableSkeleton from './VideoEditTableSkeleton';
import VideoEditDropdown from './VideoEditDropdown';
import { Link } from 'react-router-dom';
import { formatMsDurationAsHHmmss } from 'ui';

type Order = 'asc' | 'desc';

interface Data {
    Video: string;
    Visibility: string;
    Restrictions: string;
    Date: Date;
    Views: number;
    Comments: number;
    Likes: number;
    Duration: number;
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headers: HeadCell[] = [
    {
        id: 'Video',
        label: 'Video',
        numeric: false,
        disablePadding: true,
    },
    {
        id: 'Visibility',
        label: 'Visibility',
        numeric: false,
        disablePadding: true,
    },
    {
        id: 'Restrictions',
        label: 'Restrictions',
        numeric: false,
        disablePadding: true,
    },
    {
        id: 'Date',
        label: 'Date',
        numeric: false,
        disablePadding: true,
    },
    {
        id: 'Views',
        label: 'Views',
        numeric: true,
        disablePadding: true,
    },
    {
        id: 'Comments',
        label: 'Comments',
        numeric: true,
        disablePadding: true,
    },
    {
        id: 'Likes',
        label: 'Likes (vs dislikes)',
        numeric: true,
        disablePadding: true,
    },
    {
        id: 'Duration',
        label: 'Duration',
        numeric: true,
        disablePadding: true,
    },
];

interface VideoEditTableHeadProps {
    orderBy: keyof Data;
    order: Order;
}

const VideoEditTableHead: React.FC<VideoEditTableHeadProps> = (props) => {
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox color="primary" />
                </TableCell>
                {headers.map((header) => (
                    <TableCell
                        key={header.id}
                        align={header.numeric ? 'right' : 'left'}
                        padding={header.disablePadding ? 'none' : 'normal'}
                        sortDirection={
                            props.orderBy === header.id ? props.order : false
                        }
                    >
                        <TableSortLabel
                            active={props.orderBy === header.id}
                            direction={
                                props.orderBy === header.id
                                    ? props.order
                                    : 'asc'
                            }
                        >
                            {header.label}
                            <Box component="span" sx={visuallyHidden}>
                                {props.order === 'desc'
                                    ? 'sorted descending'
                                    : 'sorted ascending'}
                            </Box>
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};
interface VideoEditTableProps {
    orderBy: keyof Data;
    order: Order;
    channelId: string;
}
const VideoEditTable: React.FC<VideoEditTableProps> = (props) => {
    const videosQuery = useQuery<GetVideosForPersonalChannelResponse>(
        [`get-personal-videos-${props.channelId}`],
        () => getVideosForPersonalChannel(),
    );
    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer sx={{ maxHeight: '500px', overflowY: 'auto' }}>
                    <Table>
                        <VideoEditTableHead
                            orderBy={props.orderBy}
                            order={props.order}
                        />
                        <VideoEditTableSkeleton
                            isLoading={videosQuery.isLoading}
                            isError={videosQuery.isError}
                        >
                            <TableBody>
                                {(videosQuery.data ?? [])?.map((video) => (
                                    <TableRow key={video.title}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                inputProps={{
                                                    'aria-labelledby':
                                                        video.title,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={video.title}
                                            scope="row"
                                            padding="none"
                                        >
                                            {video.title}{' '}
                                            <VideoEditDropdown
                                                videoId={video._id}
                                                title={video.title}
                                                channelId={props.channelId}
                                            />
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={video.title}
                                            scope="row"
                                            padding="none"
                                        >
                                            {video.audience}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={video.title}
                                            scope="row"
                                            padding="none"
                                        >
                                            {video.restricted
                                                ? '18 and up'
                                                : 'For Kids'}
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={video.title}
                                            scope="row"
                                            padding="none"
                                        >
                                            {video.createDate.toString()}
                                        </TableCell>
                                        <TableCell align="right">
                                            {video.views ?? 0}
                                        </TableCell>
                                        <TableCell align="right">
                                            <Link
                                                to={`${video._id}/comments`}
                                                style={{
                                                    fontSize: 'inherit',
                                                }}
                                            >
                                                {video.comments ?? 0}
                                            </Link>
                                        </TableCell>
                                        <TableCell align="right">0</TableCell>
                                        <TableCell align="right">
                                            {formatMsDurationAsHHmmss(
                                                video?.duration ?? 0,
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </VideoEditTableSkeleton>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
};

export default VideoEditTable;
