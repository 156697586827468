import React, { useState } from 'react';
import {
    Button,
    ButtonGroup,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    TextField,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateTierInfo } from 'apis';

interface TierEditFormProps {
    title: string;
    price: number;
    description: string;
    username: string;
    tierId: string;
}

const TierEditForm: React.FC<TierEditFormProps> = (props) => {
    const queryClient = useQueryClient();
    const [title, setTitle] = useState<string>(props.title);
    const [description, setDescription] = useState<string>(props.description);
    const [price, setPrice] = useState<string>(props.price.toString());
    const resetDetails = () => {
        setDescription(props.description);
        setPrice(props.price.toString());
        setTitle(props.title);
    };
    const { mutate, isLoading } = useMutation(
        ['update-tier', props.username, props.tierId],
        () =>
            updateTierInfo({
                tierId: props.tierId,
                title: title,
                description: description,
                price: parseFloat(price),
            }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([`tier-info-${props.username}`]);
            },
        },
    );
    return (
        <Stack spacing={2}>
            <TextField
                id={`tier-${props.tierId}-title`}
                label="Title"
                variant="filled"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                helperText="Enter a unique name for your tier.."
                disabled={isLoading}
            />
            <TextField
                label="Description"
                multiline
                rows={4}
                value={description}
                variant="filled"
                onChange={(e) => setDescription(e.target.value)}
                helperText="Enter information about what content users get when subscribing.."
                disabled={isLoading}
            />
            <FormControl sx={{ m: 1, width: '25%' }}>
                <InputLabel htmlFor="outlined-adornment-amount">
                    Price
                </InputLabel>
                <OutlinedInput
                    id="outlined-adornment-amount"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    startAdornment={
                        <InputAdornment position="start">$</InputAdornment>
                    }
                    label="Amount"
                    disabled={isLoading}
                />
                <FormHelperText>Enter the price to subscribe</FormHelperText>
            </FormControl>
            <ButtonGroup variant="text">
                <Button
                    disabled={
                        description === props.description &&
                        price === props.price.toString()
                    }
                    onClick={resetDetails}
                >
                    Undo Changes
                </Button>
                <LoadingButton
                    onClick={() => mutate()}
                    loading={isLoading}
                    loadingPosition="start"
                    variant={isLoading ? 'outlined' : 'contained'}
                >
                    {isLoading ? 'Updating' : 'Update'}
                </LoadingButton>
            </ButtonGroup>
        </Stack>
    );
};

export default TierEditForm;
