import React, { useState } from 'react';
import { Stack, TextField, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

interface InfoProps {
    bio: string;
    description: string;
    channelId: string;
    submitting: boolean;
    updateChannelInfo: (bio: string, description: string) => void;
}
const Info: React.FC<InfoProps> = (props) => {
    const [formData, setFormData] = useState<InfoProps>({ ...props });
    const handleChange = (event: {
        target: { name: string; value: string };
    }) => {
        const tempData = { ...formData };
        if (
            event.target.name === 'bio' ||
            event.target.name === 'description'
        ) {
            tempData[event.target.name] = event.target.value;
        }
        setFormData(tempData);
    };
    return (
        <Stack spacing={2}>
            <Typography variant="h6" gutterBottom component="div">
                Channel Bio and Description
                <Typography variant="subtitle2" gutterBottom component="div">
                    Use your bio to tell a little bit about yourself. Use your
                    description to describe your channel content.
                </Typography>
            </Typography>
            <TextField
                id="Bio"
                fullWidth
                label="Bio"
                defaultValue={formData['bio']}
                multiline
                rows={4}
                name="bio"
                value={formData['bio']}
                onChange={handleChange}
            />
            <TextField
                id="Description"
                fullWidth
                label="Description"
                defaultValue={formData['description']}
                multiline
                rows={4}
                name="description"
                value={formData['description']}
                onChange={handleChange}
            />
            <Typography variant="h6" gutterBottom component="div">
                Channel URL
                <Typography variant="subtitle2" gutterBottom component="div">
                    Where you can go to view your public channel
                </Typography>
            </Typography>
            <TextField
                id="url"
                fullWidth
                defaultValue={`https://www.test.bluechip.link/channel/${props.channelId}`}
                disabled
            />
            <LoadingButton
                onClick={() =>
                    props.updateChannelInfo(
                        formData['bio'],
                        formData['description'],
                    )
                }
                type="submit"
                loading={props.submitting}
                loadingPosition="start"
                variant={props.submitting ? 'outlined' : 'contained'}
                sx={{ mt: 3, mb: 2, width: '10%' }}
            >
                Update
            </LoadingButton>
        </Stack>
    );
};

export default Info;
