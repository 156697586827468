import * as Types from './AppAPIs.types';
import { Api } from '../common/apis';

const apis = new Api('/api/v1');

export const getSession = async (): Promise<Types.GetSessionResponse> => {
    return await apis.get<Types.GetSessionRequest, Types.GetSessionResponse>(
        'session',
    );
};
