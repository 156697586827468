import React from 'react';
import { Stack } from '@mui/material';
import VidoeUploadProgressBar from './VideoUploadProgressBar';
import { useQuery } from '@tanstack/react-query';
import { VideoFormat, getUploadProgress } from 'apis';
interface VideoUploadProgressProps {
    videoId: string;
    videoTitle: string;
}
const VidoeUploadProgress: React.FC<VideoUploadProgressProps> = (props) => {
    const progressQuery = useQuery(
        ['get-video-progress', props.videoId],
        () => getUploadProgress({ sourceId: props.videoId }),
        {
            enabled: props.videoTitle !== '',
            refetchInterval: 8000,
        },
    );
    return (
        <Stack>
            {Object.keys(progressQuery.data?.qualities ?? {}).map(
                (qualityKey, index) => (
                    <VidoeUploadProgressBar
                        quality={qualityKey as VideoFormat}
                        progress={
                            progressQuery.data?.qualities[
                                qualityKey as VideoFormat
                            ].percentComplete ?? 0
                        }
                        status={
                            progressQuery.data?.qualities[
                                qualityKey as VideoFormat
                            ].status ?? 'NOT_STARTED'
                        }
                        key={index.toString()}
                        errorMessage={progressQuery.data?.error ?? ''}
                        title={props.videoTitle}
                    />
                ),
            )}
        </Stack>
    );
};

export default VidoeUploadProgress;
