import * as Types from './ChannelAPIs.types';
import { Api } from '../common/apis';

const channelApi = new Api('/api/v1/channel');

export const getInfo = async (
    request: Types.GetInfoRequest,
): Promise<Types.GetInfoResponse> => {
    const response = await channelApi.get<
        Types.GetInfoRequest,
        Types.GetInfoResponse
    >('getInfo', request);
    return response;
};

export const getUserInfo = async (): Promise<Types.GetInfoResponse> => {
    const response = await channelApi.get<undefined, Types.GetInfoResponse>(
        'getUserInfo',
    );
    return response;
};

export const addInfo = async (
    request: Types.AddInfoRequest,
): Promise<Types.AddInfoResponse> => {
    const response = await channelApi.post<
        Types.AddInfoRequest,
        Types.AddInfoResponse
    >('addInfo', request);
    return response;
};

export const updateInfo = async (
    request: Types.UpdateInfoRequest,
): Promise<Types.UpdateInfoResponse> => {
    const response = await channelApi.put<
        Types.UpdateInfoRequest,
        Types.UpdateInfoResponse
    >('updateInfo', request);
    return response;
};

export const getTierInfo = async (
    request: Types.GetTierInfoRequest,
): Promise<Types.GetTierInfoResponse> => {
    const response = await channelApi.get<
        Types.GetTierInfoRequest,
        Types.GetTierInfoResponse
    >('getTierInfo', request);
    return response;
};
export const createTierInfo = async (
    request: Types.CreateTierInfoRequest,
): Promise<Types.CreateTierInfoResponse> => {
    const response = await channelApi.post<
        Types.CreateTierInfoRequest,
        Types.CreateTierInfoResponse
    >('createTierInfo', request);
    return response;
};
export const updateTierInfo = async (
    request: Types.UpdateTierInfoRequest,
): Promise<Types.UpdateTierInfoResponse> => {
    const response = await channelApi.put<
        Types.UpdateTierInfoRequest,
        Types.UpdateTierInfoResponse
    >('updateTierInfo', request);
    return response;
};

export const getChannelStats = async (
    request: Types.GetChannelStatsRequest,
): Promise<Types.GetChannelStatsResponse> => {
    const response = await channelApi.get<
        Types.GetChannelStatsRequest,
        Types.GetChannelStatsResponse
    >('getChannelStats', request);
    return response;
};
