import * as Types from './PromotionAPIs.types';
import { Api } from '../common/apis';

const apis = new Api('/api/v1/promotions');

export const getPromotions = async (
    request: Types.GetPromotionsRequest,
): Promise<Types.GetPromotionsResponse> => {
    const response = await apis.get<
        Types.GetPromotionsRequest,
        Types.GetPromotionsResponse
    >('getPromotions', request);
    return response;
};
export const addPromotion = async (
    request: Types.AddPromotionRequest,
): Promise<Types.AddPromotionResponse> => {
    const response = await apis.post<
        Types.AddPromotionRequest,
        Types.AddPromotionResponse
    >('addPromotion', request);
    return response;
};
export const updatePromotion = async (
    request: Types.UpdatePromotionRequest,
): Promise<Types.UpdatePromotionResponse> => {
    const response = await apis.put<
        Types.UpdatePromotionRequest,
        Types.UpdatePromotionResponse
    >('updatePromotion', request);
    return response;
};
export const removePromotion = async (
    request: Types.RemovePromotionRequest,
): Promise<Types.RemovePromotionResponse> => {
    const response = await apis.delete<
        Types.RemovePromotionRequest,
        Types.RemovePromotionResponse
    >('removePromotion', request);
    return response;
};
export const featurePromotion = async (
    request: Types.FeaturePromotionRequest,
): Promise<Types.FeaturePromotionResponse> => {
    const response = await apis.put<
        Types.FeaturePromotionRequest,
        Types.FeaturePromotionResponse
    >('featurePromotion', request);
    return response;
};
export const removeFeaturedPromotion = async (
    request: Types.RemoveFeaturedPromotionRequest,
): Promise<Types.RemoveFeaturedPromotionResponse> => {
    const response = await apis.put<
        Types.RemoveFeaturedPromotionRequest,
        Types.RemoveFeaturedPromotionResponse
    >('removeFeaturedPromotion', request);
    return response;
};
export const getFeaturedPromotions = async (
    request: Types.GetFeaturedPromotionsRequest,
): Promise<Types.GetFeaturedPromotionsResponse> => {
    const response = await apis.get<
        Types.GetFeaturedPromotionsRequest,
        Types.GetFeaturedPromotionsResponse
    >('getFeaturedPromotions', request);
    return response;
};

export const addPromotionLikeDislike = async (
    request: Types.AddPromotionLikeDislikeRequest,
): Promise<Types.AddPromotionLikeDislikeResponse> => {
    return await apis.put<
        Types.AddPromotionLikeDislikeRequest,
        Types.AddPromotionLikeDislikeResponse
    >('addLikeDislike', request);
};

export const removePromotionLikeDislike = async (
    request: Types.RemovePromotionLikeDislikeRequest,
): Promise<Types.RemovePromotionLikeDislikeResponse> => {
    return await apis.delete<
        Types.RemovePromotionLikeDislikeRequest,
        Types.RemovePromotionLikeDislikeResponse
    >('removeLikeDislike', request);
};

export const getPromotionLikeDislikeStatus = async (
    request: Types.GetPromotionLikeDislikeStatusRequest,
): Promise<Types.GetPromotionLikeDislikeStatusResponse> => {
    return await apis.get<
        Types.GetPromotionLikeDislikeStatusRequest,
        Types.GetPromotionLikeDislikeStatusResponse
    >('getLikeDislikeStatus', request);
};
