import React, { SyntheticEvent, useState } from 'react';
import { Stack, Typography, Divider, Box, Tabs, Tab } from '@mui/material';
import { TabPanel } from 'ui';
import { useQuery } from '@tanstack/react-query';
import { useAccountContext } from 'hooks';
import { getTierInfo } from 'apis';
import TiersSkeleton from '../../../../components/channel/[channelId]/subscriptions/TiersSkeleton';
import TierEditForm from '../../../../components/channel/[channelId]/subscriptions/TierEditForm';
const TierEditPage: React.FC = () => {
    const [currentTab, setCurrentTab] = useState<number>(0);
    const { userInfo } = useAccountContext();
    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
    };
    const { isLoading, isError, data } = useQuery(
        [`tier-info-${userInfo?.username}`],
        async () => {
            if (userInfo?.username) {
                const tiers = await getTierInfo({
                    channelId: userInfo.username,
                });
                return tiers;
            }
            throw new Error('Unable to retrieve user tiers');
        },
        {
            refetchInterval: Infinity,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
        },
    );

    return (
        <Stack pt={2}>
            <Typography variant="h5" gutterBottom component="div">
                Edit Tiers
            </Typography>
            <Divider light />
            <Typography variant="caption" gutterBottom component="div">
                Let your users know what content and perks they get when they
                join your community.
            </Typography>
            <TiersSkeleton isLoading={isLoading || !data} isError={isError}>
                <Box
                    sx={{
                        paddingTop: 5,
                        borderBottom: 1,
                        borderColor: 'divider',
                    }}
                >
                    <Tabs value={currentTab} onChange={handleChange}>
                        <Tab label="Tier 1" />
                        <Tab label="Tier 2" />
                        <Tab label="Tier 3" />
                    </Tabs>
                </Box>
                <Box sx={{ width: '50vw' }}>
                    {Object.keys(data?.tiers ?? {}).map((tier, index) => (
                        <TabPanel
                            key={index.toString()}
                            currentIndex={currentTab}
                            tabIndex={index}
                        >
                            <TierEditForm
                                title={data?.tiers[tier].title ?? ''}
                                price={data?.tiers[tier].price ?? 0}
                                description={
                                    data?.tiers[tier].description ?? ''
                                }
                                username={userInfo?.username ?? ''}
                                tierId={tier}
                            />
                        </TabPanel>
                    ))}
                </Box>
            </TiersSkeleton>
        </Stack>
    );
};

export default TierEditPage;
