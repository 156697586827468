import React, { useState } from 'react';
import {
    Alert,
    FormControl,
    FormControlLabel,
    ImageList,
    ImageListItem,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { FileSelectorComponent } from 'ui';
import { uploadPresignedImageUrl, updateMedia } from 'apis';
import LoadingButton from '@mui/lab/LoadingButton';

interface VideoDetailsFormProps {
    title: string;
    channelId: string;
    videoId: string;
    setNextEnabled: (index: number) => void;
    index: number;
}

const initData = {
    description: '',
    tier: 'Free',
    audience: 'Unlisted',
    age: 'Yes, restrict my video to viewers over 18',
    tags: '',
};
const VideoDetailsForm: React.FC<VideoDetailsFormProps> = (props) => {
    const [formData, setFormData] = useState<{ [index: string]: string | any }>(
        {
            ...initData,
        },
    );
    const [thumbnail, setThumbnail] = useState<File | undefined>(undefined);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const handleChange = (event: {
        target: { name: string; value: string };
    }) => {
        const tempData = { ...formData };
        tempData[event.target.name] = event.target.value;
        setFormData(tempData);
    };
    const preValidation = (): boolean => {
        Object.keys(formData).forEach((key: string) => {
            if (formData[key] === '') {
                return false;
            }
        });
        if (thumbnail === undefined) {
            return false;
        }
        return true;
    };
    const handleSubmit = () => {
        const imageData = new FormData();
        if (thumbnail) {
            setSubmitted(false);
            setError('');
            setSubmitting(true);
            imageData.append('file', thumbnail);
            imageData.append(
                'path',
                `channel/${props.channelId}/videos/thumbnails/${props.title}`,
            );
            uploadPresignedImageUrl(
                `videos/thumbnails/${props.title}`,
                thumbnail,
            ).then(() => {
                updateMedia({
                    _id: props.videoId,
                    tags: formData['tags'].split(','),
                    title: props.title,
                    description: formData['description'],
                    tier: formData['tier'],
                    thumbnail: thumbnail?.name || '',
                    mediaType: 'video',
                    audience: formData['audience'],
                    restricted:
                        formData['age'] ===
                        'Yes, restrict my video to viewers over 18',
                }).then((updateMediaResponse) => {
                    setSubmitting(false);
                    if (updateMediaResponse) {
                        setSubmitted(true);
                        props.setNextEnabled(props.index);
                    }
                });
            });
        }
    };
    return (
        <Stack spacing={2}>
            {submitted && error === '' && (
                <Alert severity="success">
                    Successfully uploaded video details.
                </Alert>
            )}
            {error !== '' && <Alert severity="error">{error}</Alert>}
            <Typography variant="h5" gutterBottom component="div">
                Enter Video Details for {props.title}
            </Typography>
            <TextField
                id="Title"
                label="Title"
                defaultValue={props.title}
                name="title"
                disabled
            />
            <TextField
                id="Description"
                label="Description"
                defaultValue="Description"
                multiline
                rows={4}
                name="description"
                value={formData['description']}
                onChange={handleChange}
            />
            <Typography variant="h6" gutterBottom component="div">
                Thumbnail
            </Typography>
            <FileSelectorComponent
                fileType="image/*"
                id="add-video-image-thumbnail"
                width="100%"
                height="100%"
                title="Thumbnail"
                setCurrentFile={setThumbnail}
                disabled={false}
            />
            <ImageList cols={1}>
                {new Array(10).fill(1, 0, 10).map((_, index) => (
                    <ImageListItem key={index}>
                        <img
                            src={`/videos/thumbnails/channel/${
                                props.channelId
                            }/videos/${props.title}/${props.videoId}/${
                                props.title
                            }_${(index + 1).toString().padStart(4, '0')}.jpg`}
                            loading="lazy"
                        />
                    </ImageListItem>
                ))}
            </ImageList>
            <Typography variant="h6" gutterBottom component="div">
                Tier
            </Typography>
            <FormControl fullWidth>
                <InputLabel id="tier-selection-label">Tier Number</InputLabel>
                <Select
                    labelId="tier-selection-label-select"
                    id="tier-label-select"
                    value={formData['tier']}
                    label="Tier"
                    onChange={(e: SelectChangeEvent<string>) =>
                        handleChange({
                            target: { name: 'tier', value: e.target.value },
                        })
                    }
                >
                    <MenuItem value="Free">Public</MenuItem>
                    <MenuItem value="Tier 1">Tier 1</MenuItem>
                    <MenuItem value="Tier 2">Tier 2</MenuItem>
                    <MenuItem value="Tier 3">Tier 3</MenuItem>
                </Select>
            </FormControl>
            <Typography variant="h6" gutterBottom component="div">
                Audience
            </Typography>
            <RadioGroup
                row
                onChange={handleChange}
                value={formData['audience']}
            >
                {['Public', 'Private', 'Unlisted'].map((audience) => (
                    <FormControlLabel
                        key={audience}
                        name={'audience'}
                        value={audience}
                        control={<Radio />}
                        label={audience}
                    />
                ))}
            </RadioGroup>
            <Typography variant="h6" gutterBottom component="div">
                Age Restriction
            </Typography>
            <RadioGroup row onChange={handleChange} value={formData['age']}>
                {[
                    'Yes, restrict my video to viewers over 18',
                    'No, don not restrict my videos to viewers over 18 only',
                ].map((age) => (
                    <FormControlLabel
                        key={age}
                        name={'age'}
                        value={age}
                        control={<Radio />}
                        label={age}
                    />
                ))}
            </RadioGroup>
            <Typography variant="h6" gutterBottom component="div">
                Tags (comma delimited)
            </Typography>
            <TextField
                id="Tags"
                label="Tags"
                defaultValue=""
                name="tags"
                value={formData['tags']}
                onChange={handleChange}
            />
            <LoadingButton
                onClick={handleSubmit}
                type="submit"
                fullWidth
                loading={submitting}
                loadingPosition="start"
                variant={submitting ? 'outlined' : 'contained'}
                sx={{ mt: 3, mb: 2 }}
                disabled={preValidation() === false || submitted}
            >
                Upload
            </LoadingButton>
        </Stack>
    );
};

export default VideoDetailsForm;
