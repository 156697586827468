import * as Types from './UserAPIs.types';
import { Api } from '../common/apis';

const usersApi = new Api('/api/v1/users');

export const getUserSettings =
    async (): Promise<Types.GetUserSettingsResponse> => {
        const response = await usersApi.get<
            undefined,
            Types.GetUserSettingsResponse
        >('getUserSettings');
        return response;
    };

export const updateUserSettings = async (
    request: Types.UpdateUserSettingsRequest,
): Promise<Types.UpdateUserSettingsResponse> => {
    const response = await usersApi.post<
        Types.UpdateUserSettingsRequest,
        Types.UpdateUserSettingsResponse
    >('updateUserSettings', request);
    return response;
};

export const getUserSubscriptions =
    async (): Promise<Types.GetUserSubscriptionsResponse> => {
        const response = await usersApi.get<
            undefined,
            Types.GetUserSubscriptionsResponse
        >('getUserSubscriptions');
        return response;
    };

export const addUserSubscription = async (
    request: Types.AddUserSubscriptionRequest,
): Promise<Types.AddUserSubscriptionResponse> => {
    const response = await usersApi.post<
        Types.AddUserSubscriptionRequest,
        Types.AddUserSubscriptionResponse
    >('addUserSubscription', request);
    return response;
};

export const updateUserSubscription = async (
    request: Types.UpdateUserSubscriptionRequest,
): Promise<Types.UpdateUserSubscriptionResponse> => {
    const response = await usersApi.post<
        Types.UpdateUserSubscriptionRequest,
        Types.UpdateUserSubscriptionResponse
    >('updateUserSubscription', request);
    return response;
};

export const removeUserSubscription = async (
    request: Types.RemoveUserSubscriptionRequest,
): Promise<Types.RemoveUserSubscriptionResponse> => {
    const response = await usersApi.post<
        Types.RemoveUserSubscriptionRequest,
        Types.RemoveUserSubscriptionResponse
    >('removeUserSubscription', request);
    return response;
};

export const createPaymentInfo = async (
    request: Types.CreatePaymentInfoRequest,
): Promise<Types.CreatePaymentInfoResponse> => {
    const response = await usersApi.post<
        Types.CreatePaymentInfoRequest,
        Types.CreatePaymentInfoResponse
    >('createPaymentInfo', request);
    return response;
};

export const updatePaymentInfo = async (
    request: Types.UpdatePaymentInfoRequest,
): Promise<Types.UpdatePaymentInfoResponse> => {
    const response = await usersApi.post<
        Types.UpdatePaymentInfoRequest,
        Types.UpdatePaymentInfoResponse
    >('updatePaymentInfo', request);
    return response;
};

export const removePaymentInfo = async (
    request: Types.RemovePaymentInfoRequest,
): Promise<Types.RemovePaymentInfoResponse> => {
    const response = await usersApi.post<
        Types.RemovePaymentInfoRequest,
        Types.RemovePaymentInfoResponse
    >('removePaymentInfo', request);
    return response;
};

export const getPaymentInfo = async (
    request: Types.GetPaymentInfoRequest,
): Promise<Types.GetPaymentInfoResponse> => {
    const response = await usersApi.get<
        Types.GetPaymentInfoRequest,
        Types.GetPaymentInfoResponse
    >('getPaymentInfo', request);
    return response;
};

export const getPaymentInfos =
    async (): Promise<Types.GetPaymentInfosResponse> => {
        const response = await usersApi.get<
            Types.GetPaymentInfosRequest,
            Types.GetPaymentInfosResponse
        >('getPaymentInfos');
        return response;
    };
