import * as Types from './AdminAPIs.types';
import { Api } from '../common/apis';

const apis = new Api('/api/v1/admin');

export const isAdmin = async (
    request: Types.IsAdminRequest,
): Promise<Types.IsAdminResponse> => {
    return await apis.get<Types.IsAdminRequest, Types.IsAdminResponse>(
        'isAdmin',
        request,
    );
};
