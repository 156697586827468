import React from 'react';
import { Box } from '@mui/material';

interface TabPanelProps {
    tabIndex: number;
    currentIndex: number;
}

export const TabPanel: React.FC<React.PropsWithChildren<TabPanelProps>> = (
    props,
) => {
    return (
        <div role="tabpanel" hidden={props.tabIndex !== props.currentIndex}>
            {props.tabIndex === props.currentIndex && (
                <Box sx={{ p: 3 }}>{props.children}</Box>
            )}
        </div>
    );
};
