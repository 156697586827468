import * as Types from './AuthAPIs.types';
import { Api } from '../common/apis';

const apis = new Api('/api/v1/auth');

export const login = async (
    request: Types.LoginRequest,
): Promise<Types.LoginResponse> => {
    return await apis.post<Types.LoginRequest, Types.LoginResponse>(
        'login',
        request,
    );
};

export const register = async (
    request: Types.RegisterRequest,
): Promise<Types.RegisterResponse> => {
    return await apis.post<Types.RegisterRequest, Types.RegisterResponse>(
        'register',
        request,
    );
};

export const logout = async (): Promise<Types.LogoutResponse> => {
    return await apis.post<Types.LogoutRequest, Types.LogoutResponse>('logout');
};

export const refresh = async (): Promise<Types.RefreshResponse> => {
    return await apis.post<Types.RefreshRequest, Types.RefreshResponse>(
        'refresh',
    );
};

export const isLoggedIn = async (): Promise<Types.IsLoggedInResponse> => {
    return await apis.post<Types.IsLoggedInRequest, Types.IsLoggedInResponse>(
        'isLoggedIn',
    );
};
