import * as Types from './VerifyAPIs.types';
import { Api } from '../common/apis';

const apis = new Api('/api/v1/verify');

export const verifyEmail = async (
    request: Types.VerifyEmailRequest,
): Promise<Types.VerifyEmailResponse> => {
    return await apis.post<Types.VerifyEmailRequest, Types.VerifyEmailResponse>(
        'email',
        request,
    );
};

export const verifyPhone = async (
    request: Types.VerifyPhoneRequest,
): Promise<Types.VerifyPhoneResponse> => {
    return await apis.post<Types.VerifyPhoneRequest, Types.VerifyPhoneResponse>(
        'email',
        request,
    );
};

export const resendEmail = async (
    request: Types.ResendEmailRequest,
): Promise<Types.ResendEmailResponse> => {
    return await apis.post<Types.ResendEmailRequest, Types.ResendEmailResponse>(
        'resendEmail',
        request,
    );
};
