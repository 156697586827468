import React from 'react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useAccountContext } from 'hooks';
import { LinkMenuButton } from 'ui';

export const ProfileButton: React.FC = () => {
    const { userInfo } = useAccountContext();
    return (
        <LinkMenuButton
            dataCy="nav-bar-profile-button"
            IconProps={{
                size: 'large',
                edge: 'end',
                'aria-label': 'account of current user',
                'aria-haspopup': 'true',
                color: 'inherit',
            }}
            menuId={'primary-profile-account-menu'}
            menuItems={[
                {
                    title: 'Your channel',
                    to: userInfo
                        ? `/channel/${userInfo.username}/home`
                        : '/login',
                },
                {
                    title: 'Creator Studio',
                    to: '/create',
                },
                {
                    title: 'Bluechip',
                    to: '/bluechip',
                },
                {
                    title: 'Settings',
                    to: '/settings',
                },
                {
                    title: 'Help',
                    to: '/help',
                },
                {
                    title: 'Send feedback',
                    to: '/feedback',
                },
                {
                    title: 'Sign out',
                    to: '/logout',
                },
            ]}
        >
            <AccountCircle />
        </LinkMenuButton>
    );
};
