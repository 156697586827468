import React, { useState } from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { PopoverProps } from '@mui/material';
interface MenuButtonProps {
    IconProps: IconButtonProps;
    menuId: string;
    menuItems: {
        onClick?: (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
        title: string;
    }[];
}

export const MenuButton: React.FC<React.PropsWithChildren<MenuButtonProps>> = (
    props,
) => {
    const [anchorEl, setAnchorEl] = useState<PopoverProps['anchorEl']>(null);
    const isMenuOpen = Boolean(anchorEl);
    return (
        <>
            <IconButton
                data-cy={`${props.menuId}-menu-button`}
                style={{ paddingTop: '0px', paddingBottom: '0px' }}
                {...props.IconProps}
                onClick={(e) => setAnchorEl(e.currentTarget)}
                aria-controls={props.menuId}
            >
                {props.children}
            </IconButton>
            <Menu
                data-cy={`${props.menuId}-menu`}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                id={props.menuId}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={isMenuOpen}
                onClose={() => setAnchorEl(null)}
            >
                {props.menuItems.map((menuItem) => (
                    <MenuItem
                        data-cy={`${props.menuId}-menu-item-${menuItem.title}`}
                        key={menuItem.title}
                        onClick={(e) => {
                            setAnchorEl(null);
                            menuItem.onClick?.(e);
                        }}
                    >
                        {menuItem.title}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};
