import React from 'react';
import { TimeFilter } from './MetricsChart.types';
import { Box, FormControl, MenuItem, Select } from '@mui/material';

interface MetricsTimeFilterDropdownProps {
    timeFilter: TimeFilter;
    setTimeFilter: React.Dispatch<React.SetStateAction<TimeFilter>>;
}
const MetricsTimeFilterDropdown: React.FC<MetricsTimeFilterDropdownProps> = (
    props,
) => {
    const handleChange = (event: any) => {
        props.setTimeFilter(event.target.value as TimeFilter);
    };
    return (
        <Box sx={{ minWidth: 120, maxWidth: 240 }}>
            <FormControl fullWidth>
                <Select
                    labelId="analytics-time-filter"
                    id="Analytics Time Filter"
                    value={props.timeFilter}
                    onChange={handleChange}
                >
                    <MenuItem value={'Last 7 Days'}>Last 7 Days</MenuItem>
                    <MenuItem value={'Last 28 Days'}>Last 28 Days</MenuItem>
                    <MenuItem value={'Last 90 Days'}>Last 90 Days</MenuItem>
                    <MenuItem value={'Last 365 Days'}>Last 365 Days</MenuItem>
                    <MenuItem value={'Lifetime'}>Lifetime</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
};

export default MetricsTimeFilterDropdown;
