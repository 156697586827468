import { Api } from '../common/apis';

import * as Types from './HistoryAPIs.types';

const apis = new Api(`/api/v1/history`);

export const getWatchHistory =
    async (): Promise<Types.GetWatchHistoryResponse> => {
        return await apis.get<
            Types.GetWatchHistoryRequest,
            Types.GetWatchHistoryResponse
        >('getWatchHistory');
    };

export const addWatchHistory = async (
    request: Types.AddWatchHistoryRequest,
): Promise<Types.AddWatchHistoryResponse> => {
    return await apis.post<
        Types.AddWatchHistoryRequest,
        Types.AddWatchHistoryResponse
    >('addWatchHistory', request);
};
