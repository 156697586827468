import { Skeleton, TableBody, TableRow } from '@mui/material';
import React from 'react';

interface VideoEditTableSkeletonProps {
    isLoading: boolean;
    isError: boolean;
}

const VideoEditTableSkeleton: React.FC<
    React.PropsWithChildren<VideoEditTableSkeletonProps>
> = (props) => {
    if (props.isLoading) {
        return (
            <TableBody>
                <TableRow>
                    <Skeleton
                        variant="rectangular"
                        width={'100%'}
                        height={48}
                    />
                </TableRow>
            </TableBody>
        );
    }
    return <>{props.children}</>;
};

export default VideoEditTableSkeleton;
