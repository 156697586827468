import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useAccountContext } from 'hooks';
import { useParams } from 'react-router-dom';
import UploadVideo from '../../../../components/channel/[channelId]/video/upload/UploadVideo';
import { Stack } from '@mui/material';
import VideoDetailsForm from '../../../../components/channel/[channelId]/video/upload/VideoDetailsForm';
import VidoeUploadProgress from '../../../../components/channel/[channelId]/video/upload/VideoUploadProgress';

const steps = ['Choose Video', 'Video Details', 'Review'];

const Upload: React.FC = () => {
    const { channelId } = useParams();
    const accountContext = useAccountContext();
    const [videoId, setVideoId] = useState<string>('');
    const [activeStep, setActiveStep] = useState<number>(0);
    const [nextEnabled, setNextEnabled] = useState<boolean[]>(
        steps.map((n, index) => index === steps.length - 1),
    );
    const [title, setTitle] = useState<string>('');

    const nextEnable = (index: number) => {
        const temp = [...nextEnabled];
        temp[index] = true;
        setNextEnabled(temp);
    };
    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };
    if (!accountContext.isLoggedIn || !channelId) {
        return <div>Not Found</div>;
    }
    if (accountContext.userInfo?.username !== channelId) {
        return <div>Not Authorized</div>;
    }
    return (
        <Paper
            variant="outlined"
            sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
            <Typography component="h1" variant="h4" align="center">
                Create New Video
            </Typography>
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <>
                {activeStep === steps.length ? (
                    <Stack>
                        <Typography variant="h5" gutterBottom>
                            {title} has been created
                        </Typography>
                        <Typography variant="subtitle1">
                            It is currently being processed. Check back later to
                            see the status. You may leave this page.
                        </Typography>
                        <VidoeUploadProgress
                            videoId={videoId}
                            videoTitle={title}
                        />
                    </Stack>
                ) : (
                    <>
                        {activeStep === 0 && (
                            <UploadVideo
                                setVideoId={setVideoId}
                                channelId={channelId}
                                title={title}
                                setTitle={setTitle}
                                index={0}
                                setNextEnabled={nextEnable}
                            />
                        )}
                        {activeStep === 1 && (
                            <VideoDetailsForm
                                videoId={videoId}
                                channelId={channelId}
                                title={title}
                                index={1}
                                setNextEnabled={nextEnable}
                            />
                        )}
                        <Box
                            sx={{ display: 'flex', justifyContent: 'flex-end' }}
                        >
                            <Button
                                variant="contained"
                                onClick={handleNext}
                                sx={{ mt: 3, ml: 1 }}
                                disabled={!nextEnabled[activeStep]}
                            >
                                {activeStep === steps.length - 1
                                    ? 'Confirm'
                                    : 'Next'}
                            </Button>
                        </Box>
                    </>
                )}
            </>
        </Paper>
    );
};

export default Upload;
