import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { IconButton, Typography } from '@mui/material';

interface FileUploaderProps {
    fileType: 'video/*' | 'image/*';
    id: string;
    width: string;
    height: string;
    title: string;
    setCurrentFile?: React.Dispatch<React.SetStateAction<File | undefined>>;
    disabled?: boolean;
}

const Input = styled('input')({
    display: 'none',
});

export const FileSelectorComponent: React.FC<
    React.PropsWithChildren<FileUploaderProps>
> = (props) => {
    const [currentFile, setCurrentFile] = useState<File | undefined>(undefined);
    const [previewImage, setPreviewImage] = useState<string | undefined>(
        undefined,
    );
    const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event?.target?.files?.[0]) {
            setCurrentFile(event.target.files[0]);
            if (props.setCurrentFile) {
                if (event?.target?.files?.[0]) {
                    props.setCurrentFile(event.target.files[0]);
                }
            }
            if (props.fileType === 'image/*') {
                if (event?.target?.files?.[0]) {
                    setPreviewImage(URL.createObjectURL(event.target.files[0]));
                }
            }
        }
    };

    return (
        <Box
            sx={{
                width: props.width,
                height: props.height,
                border: '1px dashed black',
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <label htmlFor={props.id}>
                {props.disabled === false && (
                    <Input
                        accept={props.fileType}
                        id={props.id}
                        type="file"
                        onChange={selectFile}
                    />
                )}
                <IconButton
                    aria-label="select file"
                    component="span"
                    disabled={props.disabled === false}
                >
                    {!previewImage && !currentFile && (
                        <>
                            {props.children}
                            <Typography
                                variant="subtitle2"
                                gutterBottom
                                component="span"
                            >
                                {props.title}
                            </Typography>
                        </>
                    )}
                    {!previewImage && currentFile && (
                        <Typography
                            variant="subtitle2"
                            gutterBottom
                            component="span"
                        >
                            {currentFile.name}
                        </Typography>
                    )}
                    {previewImage && currentFile && (
                        <img
                            alt="media"
                            src={previewImage}
                            width="200px"
                            height="150px"
                        />
                    )}
                </IconButton>
            </label>
        </Box>
    );
};
