import React from 'react';
import Sidebar from './Sidebar';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import { ErrorBoundary } from 'react-error-boundary';
import { Alert, AlertTitle } from '@mui/material';
const drawerWidth = 240;
interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const ErrorFallback: React.FC<{ error: any }> = (props) => {
    return (
        <Alert severity="error">
            <AlertTitle>Something went wrong</AlertTitle>
            {props.error.message}
        </Alert>
    );
};

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const mdTheme = createTheme();

interface LayoutProps {
    NavBar: JSX.Element;
    SideBar: JSX.Element;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    open: boolean;
}
export const Layout: React.FC<React.PropsWithChildren<LayoutProps>> = (
    props,
) => {
    const toggleDrawer = () => {
        props.setOpen(!props.open);
    };

    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <CssBaseline />
                    <AppBar position="absolute" open={props.open}>
                        <Toolbar
                            sx={{
                                pr: '24px', // keep right padding when drawer closed
                            }}
                        >
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                onClick={toggleDrawer}
                                sx={{
                                    marginRight: '36px',
                                    ...(props.open && { display: 'none' }),
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                            {props.NavBar}
                        </Toolbar>
                    </AppBar>
                    <Sidebar open={props.open} toggleDrawer={props.setOpen}>
                        {props.SideBar}
                    </Sidebar>
                    <Box
                        component="main"
                        sx={{
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                            flexGrow: 1,
                            height: '100vh',
                            overflow: 'auto',
                        }}
                    >
                        <Toolbar />
                        <Container maxWidth={false}>{props.children}</Container>
                    </Box>
                </ErrorBoundary>
            </Box>
        </ThemeProvider>
    );
};
