import React, { useState } from 'react';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { TimeFilter } from '../MetricsChart.types';
import MetricsChart from '../MetricsChart';

interface AdRevenueProps {
    timeFilter: TimeFilter;
}

const AdRevenue: React.FC<AdRevenueProps> = (props) => {
    const [tabIndex, setTabIndex] = useState<string>('1');

    const handleChange = (
        _event: React.SyntheticEvent,
        newTabIndex: string,
    ) => {
        setTabIndex(newTabIndex);
    };

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={tabIndex}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                    >
                        <Tab label="Totals" value="1" />
                        <Tab label="Revenue" value="2" />
                        <Tab label="Payment to Bluechip" value="3" />
                        <Tab label="Creator Revenue at 55%" value="4" />
                        <Tab label="Ad Earnings / 1000 Views" value="5" />
                        <Tab label="Ad Earnings / Video" value="6" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <MetricsChart
                        title="Totals"
                        timeFilter={props.timeFilter}
                    />
                </TabPanel>
                <TabPanel value="2">
                    <MetricsChart
                        title="Revenue"
                        timeFilter={props.timeFilter}
                    />
                </TabPanel>
                <TabPanel value="3">
                    <MetricsChart
                        title="Payment to Bluechip"
                        timeFilter={props.timeFilter}
                    />
                </TabPanel>
                <TabPanel value="4">
                    <MetricsChart
                        title="Creator Revenue at 55%"
                        timeFilter={props.timeFilter}
                    />
                </TabPanel>
                <TabPanel value="5">
                    <MetricsChart
                        title="Ad Earnings / 1000 Views"
                        timeFilter={props.timeFilter}
                    />
                </TabPanel>
                <TabPanel value="6">
                    <MetricsChart
                        title="Ad Earnings / Video"
                        timeFilter={props.timeFilter}
                    />
                </TabPanel>
            </TabContext>
        </Box>
    );
};
export default AdRevenue;
