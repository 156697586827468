import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import VideoCommentsTable from '../../../../../../components/channel/[channelId]/video/content/VideoCommentsTable';
import { useQuery } from '@tanstack/react-query';
import { getMediaById, GetMediaByIdResponse } from 'apis';

const VideoComments: React.FC = () => {
    const { channelId, videoId } = useParams();
    if (channelId === undefined) {
        return <div>Channel Not Found</div>;
    }
    if (videoId === undefined) {
        return <div>Video Not Found</div>;
    }
    const metaDataQuery = useQuery<GetMediaByIdResponse>(
        [`video-${videoId}`],
        () => getMediaById({ id: videoId }),
        {
            refetchInterval: Infinity,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        },
    );
    return (
        <Stack sx={{ paddingTop: '40px' }}>
            <Typography variant="h5" gutterBottom component="div">
                {metaDataQuery.data?.title} Comments
            </Typography>
            <Box>
                <VideoCommentsTable videoId={videoId} />
            </Box>
        </Stack>
    );
};

export default VideoComments;
