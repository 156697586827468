export const formatISOStringAsDDMMYYYY = (date: Date): string => {
    const yyyy = date.getFullYear();
    const mm = (date.getMonth() + 1).toString().padStart(2, '0'); // Months start at 0!
    const dd = date.getDate().toString().padStart(2, '0');
    return `${dd}/${mm}/${yyyy}`;
};

export const formatMsDurationAsHHmmss = (duration: number): string => {
    const milliseconds = Math.floor((duration % 1000) / 100);
    const seconds = Math.floor((duration / 1000) % 60);
    const minutes = Math.floor((duration / (1000 * 60)) % 60);
    const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

    const hoursS = hours < 10 ? '0' + hours : hours;
    const minutesS = minutes < 10 ? '0' + minutes : minutes;
    const secondsS = seconds < 10 ? '0' + seconds : seconds;
    return hoursS + ':' + minutesS + ':' + secondsS + '.' + milliseconds;
};
