import * as Types from './AirdropAPIs.types';
import { Api } from '../common/apis';
const apis = new Api('/api/v1/airdrop');

export const addAirdrop = async (
    request: Types.AddAirdropRequest,
): Promise<Types.AddAirdropResponse> => {
    return await apis.post<Types.AddAirdropRequest, Types.AddAirdropResponse>(
        'addAirdrop',
        request,
    );
};

export const getAllAirdrops = async (
    request: Types.GetAllAirdropsRequest,
): Promise<Types.GetAllAirdropsResponse> => {
    return await apis.get<
        Types.GetAllAirdropsRequest,
        Types.GetAllAirdropsResponse
    >('getAllAirdrops', request);
};

export const deleteAirdrop = async (
    request: Types.DeleteAirdropsRequest,
): Promise<Types.DeleteAirdropsResponse> => {
    return await apis.delete<
        Types.DeleteAirdropsRequest,
        Types.DeleteAirdropsResponse
    >('deleteAirdrop', request);
};
