import React from 'react';
import IconButton from '@mui/material/IconButton';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import { useAccountContext } from 'hooks';
import { Link } from 'react-router-dom';
export const CreateButton: React.FC = () => {
    const account = useAccountContext();
    return (
        <Link
            to={`/create/channel/${
                account?.userInfo?.username ?? ''
            }/videos/upload`}
            style={{ color: 'white' }}
        >
            <IconButton
                data-cy="nav-bar-create-button"
                size="large"
                aria-label="create video"
                color="inherit"
                style={{ paddingTop: '0px', paddingBottom: '0px' }}
            >
                <VideoCallIcon />
            </IconButton>
        </Link>
    );
};
