import { Api } from '../common/apis';
import * as Types from './EmailAPIs.types';

const apis = new Api(`/api/v1/email`);

export const sendEmail = async (
    request: Types.SendEmailRequest,
): Promise<Types.SendEmailResponse> => {
    return await apis.post<Types.SendEmailRequest, Types.SendEmailResponse>(
        'sendVerificationEmail',
        request,
    );
};

export const sendFeedbackEmail = async (
    request: Types.SendEmailRequest,
): Promise<Types.SendEmailResponse> => {
    return await apis.post<Types.SendEmailRequest, Types.SendEmailResponse>(
        'sendFeedback',
        request,
    );
};
