import { VideoProgressStatus } from 'apis';

export const videoFormatToReadableString: Record<VideoProgressStatus, string> =
    {
        NOT_STARTED: 'Queueing',
        QUEUED: 'Queued',
        IN_PROGRESS: 'Converting',
        COMPLETE: 'Finished',
        FAILED: 'Failed',
    };
