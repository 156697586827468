import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import React, { useState } from 'react';
import MetricsChart from '../MetricsChart';
import { TimeFilter } from '../MetricsChart.types';

interface DailyPromotionHeaderProps {
    timeFilter: TimeFilter;
}

const DailyPromotionHeader: React.FC<DailyPromotionHeaderProps> = (props) => {
    const [tabIndex, setTabIndex] = useState<string>('1');
    const handleTabChange = (
        _event: React.SyntheticEvent,
        newTabIndex: string,
    ) => {
        setTabIndex(newTabIndex);
    };
    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={tabIndex}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList
                        onChange={handleTabChange}
                        aria-label="Promotions Tab"
                    >
                        <Tab label="Ads" value="1" />
                        <Tab label="Cost" value="2" />
                        <Tab label="Banner Length" value="3" />
                        <Tab label="Promotion Length" value="4" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <MetricsChart title="Ads" timeFilter={props.timeFilter} />
                </TabPanel>
                <TabPanel value="2">
                    <MetricsChart
                        title="Avg Spent"
                        timeFilter={props.timeFilter}
                    />
                </TabPanel>
                <TabPanel value="3">
                    <MetricsChart
                        title="Banner Length"
                        timeFilter={props.timeFilter}
                    />
                </TabPanel>
                <TabPanel value="4">
                    <MetricsChart
                        title="Promotion Length"
                        timeFilter={props.timeFilter}
                    />
                </TabPanel>
            </TabContext>
        </Box>
    );
};
export default DailyPromotionHeader;
