import React from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useAccountContext } from 'hooks';
import { useForm } from 'react-hook-form';
import { useNotifications } from 'hooks';

const Copyright: React.FC<{ sx: { mt: number; mb: number } }> = (props) => {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {'Copyright © '}
            <Link color="inherit" to="/home">
                Bluechip
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
};

type Inputs = {
    username: string;
    password: string;
    rememberMe: boolean;
};

const theme = createTheme();

const LoginPage: React.FC = () => {
    const navigate = useNavigate();
    const { login } = useAccountContext();
    const {
        register,
        getValues,
        handleSubmit,
        formState: { errors, isValid, isValidating },
    } = useForm<Inputs>({ mode: 'all', reValidateMode: 'onChange' });
    const notifications = useNotifications();

    const onClick = useMutation<boolean, { message: string }>(
        () =>
            login({
                username: getValues('username'),
                password: getValues('password'),
                rememberMe: getValues('rememberMe'),
            }),
        {
            onSuccess: () => {
                notifications.showNotification({ message: 'Logged in' });
                navigate('/home');
            },
        },
    );

    const onSubmit = () => {
        onClick.mutate();
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Sign in
                        </Typography>
                        {onClick.isError && (
                            <Alert severity="error">
                                {onClick.error.message}
                            </Alert>
                        )}
                        <Box sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                fullWidth
                                label="Username"
                                autoComplete="username"
                                autoFocus
                                {...register('username', {
                                    required: 'Username is a required field',
                                    minLength: 4,
                                    maxLength: 20,
                                })}
                                helperText={errors.username?.message ?? ''}
                                error={errors.username?.message !== undefined}
                            />
                            <TextField
                                margin="normal"
                                fullWidth
                                label="Password"
                                type="password"
                                autoComplete="current-password"
                                {...register('password', {
                                    required: 'Password is a required field',
                                    minLength: 8,
                                    maxLength: 20,
                                    pattern:
                                        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                })}
                                helperText={errors.password?.message ?? ''}
                                error={errors.password?.message !== undefined}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="primary"
                                        {...register('rememberMe', {
                                            value: true,
                                        })}
                                    />
                                }
                                label="Remember me"
                            />
                            <LoadingButton
                                onClick={() => onClick.mutate()}
                                type="submit"
                                fullWidth
                                disabled={!isValid || isValidating}
                                loading={onClick.isLoading}
                                loadingPosition="start"
                                variant={
                                    onClick.isLoading ? 'outlined' : 'contained'
                                }
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Sign In
                            </LoadingButton>
                            <Grid container>
                                <Grid item xs>
                                    <Link to="#">Forgot password?</Link>
                                </Grid>
                                <Grid item>
                                    <Link to="/register">Sign up</Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </form>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
};

export default LoginPage;
