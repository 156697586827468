import React from 'react';
import { Skeleton, Alert, Stack } from '@mui/material';

interface ChannelStatisticsSkeletonProps {
    isLoading: boolean;
    isError: boolean;
}

const ChannelStatisticsSkeleton: React.FC<
    React.PropsWithChildren<ChannelStatisticsSkeletonProps>
> = (props) => {
    if (props.isError) {
        return (
            <Alert severity="error">Unable to load channel statistics.</Alert>
        );
    }
    if (props.isLoading) {
        return (
            <Stack spacing={2}>
                <Skeleton variant="rectangular" width={400} height={20} />
                <Skeleton variant="rectangular" width={400} height={20} />
                <Skeleton variant="rectangular" width={400} height={20} />
            </Stack>
        );
    }
    return <>{props.children}</>;
};

export default ChannelStatisticsSkeleton;
