import React from 'react';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import MetricsChart from '../MetricsChart';
import { TimeFilter } from '../MetricsChart.types';
interface AdExpenseStatisticsProps {
    timeFilter: TimeFilter;
}

const AdExpenseStatistics: React.FC<AdExpenseStatisticsProps> = (props) => {
    const [tabIndex, setTabIndex] = React.useState('1');
    const handleChange = (
        _event: React.SyntheticEvent,
        selectedTabIndex: string,
    ) => {
        setTabIndex(selectedTabIndex);
    };

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={tabIndex}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                    >
                        <Tab label="Ad Expense" value="1" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <MetricsChart
                        timeFilter={props.timeFilter}
                        title="Ad Expense"
                    />
                </TabPanel>
            </TabContext>
        </Box>
    );
};
export default AdExpenseStatistics;
