import React, { useState } from 'react';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import { PopoverProps } from '@mui/material';
interface MenuButtonProps {
    dataCy?: string;
    IconProps: IconButtonProps;
    menuId: string;
    menuItems: {
        title: string;
        to: string;
    }[];
}

export const LinkMenuButton: React.FC<
    React.PropsWithChildren<MenuButtonProps>
> = (props) => {
    const [anchorEl, setAnchorEl] = useState<PopoverProps['anchorEl']>(null);
    const isMenuOpen = Boolean(anchorEl);
    return (
        <>
            <IconButton
                data-cy={props.dataCy ?? 'link-menu-button'}
                style={{ paddingTop: '0px', paddingBottom: '0px' }}
                {...props.IconProps}
                onClick={(e: any) => setAnchorEl(e.currentTarget)}
                aria-controls={props.menuId}
            >
                {props.children}
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                id={props.menuId}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={isMenuOpen}
                onClose={() => setAnchorEl(null)}
            >
                {props.menuItems.map((menuItem, index) => (
                    <Link
                        key={index.toString()}
                        to={menuItem.to}
                        style={{ color: 'black', textDecoration: 'none' }}
                    >
                        <MenuItem
                            key={menuItem.title}
                            onClick={() => setAnchorEl(null)}
                        >
                            {menuItem.title}
                        </MenuItem>
                    </Link>
                ))}
            </Menu>
        </>
    );
};
