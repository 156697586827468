import React from 'react';
import { getComments, GetCommentsResponse } from 'apis';
import {
    Box,
    Paper,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    TableContainer,
    Table,
    TableBody,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { useQuery } from '@tanstack/react-query';
import VideoCommentTableSkeleton from './VideoCommentTableSkeleton';

type Order = 'asc' | 'desc';

interface Data {
    User: string;
    Comment: string;
    Likes: string;
    Dislikes: string;
    Replies: string;
    CreatedOn: string;
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

const headers: HeadCell[] = [
    {
        id: 'User',
        label: 'User',
        numeric: false,
        disablePadding: true,
    },
    {
        id: 'Comment',
        label: 'Comment',
        numeric: false,
        disablePadding: true,
    },
    {
        id: 'Likes',
        label: 'Likes',
        numeric: false,
        disablePadding: true,
    },
    {
        id: 'Dislikes',
        label: 'Dislikes',
        numeric: false,
        disablePadding: true,
    },
    {
        id: 'Replies',
        label: 'Replies',
        numeric: false,
        disablePadding: true,
    },
    {
        id: 'CreatedOn',
        label: 'Date',
        numeric: false,
        disablePadding: true,
    },
];

interface VideoEditTableHeadProps {
    orderBy: keyof Data;
    order: Order;
}

const VideoCommentsTableHead: React.FC<VideoEditTableHeadProps> = (props) => {
    return (
        <TableHead>
            <TableRow>
                {headers.map((header) => (
                    <TableCell
                        key={header.id}
                        align={header.numeric ? 'right' : 'left'}
                        padding={header.disablePadding ? 'none' : 'normal'}
                        sortDirection={
                            props.orderBy === header.id ? props.order : false
                        }
                    >
                        <TableSortLabel
                            active={props.orderBy === header.id}
                            direction={
                                props.orderBy === header.id
                                    ? props.order
                                    : 'asc'
                            }
                        >
                            {header.label}
                            <Box component="span" sx={visuallyHidden}>
                                {props.order === 'desc'
                                    ? 'sorted descending'
                                    : 'sorted ascending'}
                            </Box>
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

interface VideoCommentsTableProps {
    videoId: string;
}

const VideoCommentsTable: React.FC<VideoCommentsTableProps> = (props) => {
    const commentsForChannel = useQuery<GetCommentsResponse>(
        [`video-${props.videoId}-comments`],
        () => getComments({ sourceId: props.videoId }),
        {
            refetchInterval: Infinity,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        },
    );
    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer sx={{ maxHeight: '500px', overflowY: 'auto' }}>
                    <Table>
                        <VideoCommentsTableHead
                            orderBy="CreatedOn"
                            order="asc"
                        />
                        <VideoCommentTableSkeleton
                            isLoading={commentsForChannel.isLoading}
                            isError={commentsForChannel.isError}
                        >
                            <TableBody>
                                {(commentsForChannel.data ?? [])?.map(
                                    (comment) => (
                                        <TableRow key={comment._id}>
                                            <TableCell
                                                component="th"
                                                id={comment._id}
                                                scope="row"
                                                padding="none"
                                            >
                                                {comment.username}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={comment._id}
                                                scope="row"
                                                padding="none"
                                            >
                                                {comment.body}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={comment._id}
                                                scope="row"
                                                padding="none"
                                            >
                                                {comment.likes}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={comment._id}
                                                scope="row"
                                                padding="none"
                                            >
                                                {comment.dislikes}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={comment._id}
                                                scope="row"
                                                padding="none"
                                            >
                                                {comment.replies}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={comment._id}
                                                scope="row"
                                                padding="none"
                                            >
                                                {comment.createDate.toString()}
                                            </TableCell>
                                        </TableRow>
                                    ),
                                )}
                            </TableBody>
                        </VideoCommentTableSkeleton>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
};

export default VideoCommentsTable;
